import './App.css';
import pfp from './Static/PFP2.png'
import proxmox from './Static/proxmox.png'
import truenas from './Static/truenas.png'
import avarice from './Static/Avarice.png'
import resume from './Static/Resume.pdf'
import github from './Static/github.png'
import linkedin from './Static/linkedin.png'

import jamf from './Static/jamf.png'
import entra from './Static/entra.svg'
import screenconnect from "./Static/screenconnect.png"

function App() {
  return (
<div className="App">
   <header className="App-header">
      <a href="#Landing">Intro</a>
      <a href="#Experiences">Experience</a>
   </header>
   <div className="Content">
      <div className="Landing" id="Landing">
         <div className="Pill">
            <a href={resume} download="JunYoon_Resume">
              <div className="Pill-left Resume">Resume</div>
            </a>
            <div className="Pill-right">Open to work</div>
         </div>
         <div className="PFP">
            <img className="PFP-Image" src={pfp} alt="Jun Hyeok Yoon"/>
         </div>
         <div className="SocialMediaGrid">
          <div className="SocialMediaIcon">
            <a href="https://www.linkedin.com/in/junhyeok-yoon/"> 
              <img src={linkedin} alt="linkedin"/>
            </a>
          </div>
          <div className="SocialMediaIcon">
            <a href="https://github.com/JunHyeokYoon"> 
              <img src={github} alt="github"/>
            </a>
          </div>
         </div>
         <div className="Name">
            Jun Hyeok Yoon
         </div>
         <div className="Self-summary">
          Hi, I'm Jun Hyeok Yoon. Someone who is passionate about enterprise information techonology and security. Striving to constantly expand my knowledge in my field from and with others. I love to tinker with my homelab creating various virtual servers to hosting everything from Home Assistant, Pi Hole to game servers.
         </div>
         <div className="Carousel">
          <section>
            <article>
              <div>
                <ul>
                  <li title="test"><img src={jamf} alt="jamf" /></li>
                  <li title="test"><img src={entra} alt="entra"/></li>
                  <li title="test"><img src={screenconnect} alt="screenconnect"/></li>
                  <li><img src="http://dummyimage.com/600x400/eeeeee/cccccc.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/000000/ffffff.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/eeeeee/cccccc.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/000000/ffffff.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/eeeeee/cccccc.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/000000/ffffff.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/eeeeee/cccccc.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/000000/ffffff.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/eeeeee/cccccc.png" /></li>
                </ul>
              </div>
              <div>
                <ul>
                  <li><img src="http://dummyimage.com/600x400/000000/ffffff.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/eeeeee/cccccc.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/000000/ffffff.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/eeeeee/cccccc.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/000000/ffffff.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/eeeeee/cccccc.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/000000/ffffff.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/eeeeee/cccccc.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/000000/ffffff.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/eeeeee/cccccc.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/000000/ffffff.png" /></li>
                  <li><img src="http://dummyimage.com/600x400/eeeeee/cccccc.png" /></li>
                </ul>
              </div>
            </article>
          </section>
        </div>
      </div>
      <div className="Experiences" id="Experiences">
         <div className="Pill">
            Experiences
         </div>
         <div className="Role">Junior IT Infrastructure Engineer</div>
         <div className="Experience">
            <div className="Pill">Current</div>
            <div>
               <div className="Title">Jump Trading Group</div>
               <div className="Position">Client Services Engineer</div>
               <div className="Duration">Started: July 2024</div>
               <div className="Location">New York, NY</div>
            </div>
            <div className="Responsibilities">
               <div className="Title">Responsibilities</div>
               <ul>
               </ul>
            </div>
         </div>
         <div className="Experience">
            <div>
               <div className="Title">Kiss Products Inc.</div>
               <div className="Position">IT Specialist</div>
               <div className="Duration">Started: March 2023</div>
               <div className="Location">Port Washington, NY</div>
            </div>
            <div className="Responsibilities">
               <div className="Title">Responsibilities</div>
               <ul>
                  <li>
                     Provided remote and in-person technical support to over 1000 users worldwide.
                  </li>
                  <li>
                     Jamf Admin: Implemented Jamf infrastructure from the ground up. Managed policies, configurations, and compliances. Managed ABM and VPP licensing. Negotiated contracts with a license account executive. Tracked and audited all devices on Jamf. Managed scripts in Bash & Applescript for policies and extension attributes for smart groups. Managed Jamf Connect configurations (SSO (Entra), LAPS Policy, Password Policy, Temporary Admin, etc).
                  </li>
                  <li>
                     Intune Admin: Implemented Intune infrastructure from the ground up. Managed autopilot deployment. Managed application Win32 creation via Win32 tool and PowerShell scripting. Managed SAP installation automation on all devices. Managed compliances and policies. Managed security and Defender.
                  </li>
                  <li>
                     Okta Admin: Managed and created groups and created new hire accounts for SSO.
                  </li>
                  <li>
                     Entra Admin: Managed groups and roles, Managed and created enterprise applications, and Managed.
                  </li>
                  <li>
                     Vsphere Admin: Created virtual machines from templates with hardware specifications for each use case for on-prem hosting (MediaWiki, Bitwarden, and SCIM).
                  </li>
                  <li>
                     Exchange Admin: Managed and created group emails.
                  </li>
                  <li>
                     Analyzed Crowdstrike detection notifications on devices to determine if the flagged application and/or file were still installed on the device.
                  </li>
                  <li>
                     Created step-by-step documentation and training guides for new hire onboarding and training.
                  </li>
               </ul>
            </div>
         </div>
         <div className="Experience">
            <div>
               <div className="Title">iFix Mobile (Flushing)</div>
               <div className="Position">Lead Technician and Store Manager </div>
               <div className="Duration">Aug, 2020 - Nov, 2022</div>
               <div className="Location">Flushing, NY</div>
            </div>
            <div className="Responsibilities">
               <div className="Title">Responsibilities</div>
               <ul>
                  <li>
                     Promoted from technician to lead technician and eventually assumed the role of store manager at the second location.
                  </li>
                  <li>
                     Managed business relations with suppliers and customers, ensuring smooth day-to-day operations and customer satisfaction.                
                  </li>
                  <li>
                     Provided assistance to clients for hardware and software technical issues.
                  </li>
                  <li>
                     Managed the OS imaging server for deploying preloaded software.
                  </li>
                  <li>
                     Developed technical guides and training documentation for future employees.
                  </li>
                  <li>
                     Performed hardware repairs across multiple platforms, including mobile devices, laptops, desktops, and small electronics.
                  </li>
               </ul>
            </div>
         </div>
      </div>
      <div className="Projects">
         <div className="Pill">Projects</div>
         <div className="Card-grid">
            <div className="Card">
              <img src={proxmox} alt="proxmox"/>
              <h2>Proxmox Virtual Machines</h2>
              <div>
                Created multiple virtual machines with networking for various projects. Game servers, Pi Hole, Octoprint, Windows Servers, Test linux servers and Home Assistant. Each virutal machine would be assigned a static ip on a VLan,
              </div>
            </div>
            <div className="Card">
              <img src={truenas} alt="truenas"/>
              <h2>Truenas Scale</h2>
                <div>
                  Used for cold storing larger or older pictures, movies, shows and videos. Hard drives were configured in raid0. Used built in virtual machine funciton to host plex/jellyfin to watch the stored video files. 
                </div>
            </div>
            <div className="Card">
              <img src={avarice} alt="avarice"/>
              <h2>Avarice</h2>
              <div>
                An attempt to create a large scale finance tracking application with a working frontend (react.js) and backend (python using django and restapi). With user token verification and websockets for real time updates on changes to users accounts. 
              </div>
            </div>
         </div>
      </div>
   </div>
</div>
  );
}

export default App;

